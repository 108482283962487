import React, { lazy, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { GlobalShareContext } from './App';
import RequireAuth from './components/RequireAuth';
import SuspenseContainer from './components/SuspenseContainer';
import { BannerDetectorProvider } from './context/BannerDetectorContext';

const SignIn = lazy(() => import('./screens/SignIn'));
const ResetPassword = lazy(() => import('./screens/ResetPassword'));
const ForgotPassword = lazy(() => import('./screens/ForgotPassword'));
const Page = lazy(() => import('./components/Page'));
const ExtractVideo = lazy(() => import('./screens/ExtractVideo'));
const ExtractComments = lazy(() => import('./screens/ExtractComment'));
const Campaign = lazy(() => import('./screens/Campaign'));
const Queue = lazy(() => import('./screens/Queue'));

const KeywordExploreTiktok = lazy(() =>
  import('./screens/KeywordExplore/TiktokHashtag'),
);
const KeywordExploreYoutube = lazy(() =>
  import('./screens/KeywordExplore/Youtube'),
);
const KeywordExploreInstagram = lazy(() =>
  import('./screens/KeywordExplore/Instagram'),
);

const ChannelExploreTiktok = lazy(() =>
  import('./screens/ChannelExplore/Tiktok'),
);
const ChannelExploreInstagram = lazy(() =>
  import('./screens/ChannelExplore/Instagram'),
);
const ChannelExploreYoutube = lazy(() =>
  import('./screens/ChannelExplore/Youtube'),
);
const BannerDetector = lazy(() => import('./screens/BannerDetector'));

const KeywordSearchTracker = lazy(() => import('./screens/KeywordTracker'));
const CategorySuggestion = lazy(() => import('./screens/CategorySuggestion'));
const ManageModel = lazy(() => import('./screens/ManageModel'));
const PerformanceAnalytics = lazy(() =>
  import('./screens/PerformanceAnalytics'),
);

const CountrySelect = lazy(() => import('./components/CountrySelect'));

const CodeExecutive = lazy(() => import('./screens/CodeExecutive'));

const Router = () => {
  const { globalShare } = useContext(GlobalShareContext);

  return (
    <Routes>
      <Route exact path="/*" element={<Navigate to="/extract-data/videos" />} />
      <Route
        exact
        path="/extract-data/*"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title={globalShare?.title ?? 'Extract videos'} wide>
                <ExtractVideo />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/extract-data/comments"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title={globalShare?.title ?? 'Extract comments'} wide>
                <ExtractComments />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/campaigns/*"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Uploaded Campaigns" wide>
                <Campaign />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/queue/*"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="File queue" wide>
                <Queue />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />

      {/*<Route*/}
      {/*  exact*/}
      {/*  path="/keyword-explore/tiktok-keyword"*/}
      {/*  element={*/}
      {/*    <RequireAuth redirectTo="/sign-in">*/}
      {/*      <SuspenseContainer>*/}
      {/*        <Page title="TikTok Keyword Explore" wide>*/}
      {/*          <KeywordExploreTiktok />*/}
      {/*        </Page>*/}
      {/*      </SuspenseContainer>*/}
      {/*    </RequireAuth>*/}
      {/*  }*/}
      {/*/>*/}

      <Route
        exact
        path="/keyword-explore/tiktok"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page
                title="TikTok Keyword Explore"
                headerElements={<CountrySelect hiddenField={[]} />}
                wide
              >
                <KeywordExploreTiktok />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/keyword-explore/instagram"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Instagram Keyword Explore" wide>
                <KeywordExploreInstagram />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/keyword-explore/youtube"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Youtube Keyword Explore" wide>
                <KeywordExploreYoutube />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/channel-explore/tiktok"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="TikTok Channel Explore" wide>
                <ChannelExploreTiktok />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/channel-explore/instagram"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Instagram Channel Explore" wide>
                <ChannelExploreInstagram />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/channel-explore/youtube"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Youtube Channel Explore" wide>
                <ChannelExploreYoutube />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/keyword-tracker/*"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Keyword Search Tracker" wide>
                <KeywordSearchTracker />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/keyword-labelling/*"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Keyword Labelling" wide>
                <CategorySuggestion />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/code-executive/*"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Code Executive" wide>
                <CodeExecutive />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/banner-detector/*"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <BannerDetectorProvider>
                <BannerDetector />
              </BannerDetectorProvider>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/manage-models/"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Manage Models" wide>
                <ManageModel />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/performance-analytics"
        element={
          <RequireAuth redirectTo="/sign-in">
            <SuspenseContainer>
              <Page title="Performance Analytics" wide>
                <PerformanceAnalytics />
              </Page>
            </SuspenseContainer>
          </RequireAuth>
        }
      />
      <Route
        exact
        path="/sign-in"
        element={
          <SuspenseContainer>
            <SignIn />
          </SuspenseContainer>
        }
      />
      <Route
        exact
        path="/reset-password"
        element={
          <SuspenseContainer>
            <ResetPassword />
          </SuspenseContainer>
        }
      />
      <Route
        exact
        path="/forgot-password"
        element={
          <SuspenseContainer>
            <ForgotPassword />
          </SuspenseContainer>
        }
      />
    </Routes>
  );
};

export default Router;
