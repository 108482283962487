import React from 'react';
import cn from 'classnames';
import styles from './CardWithoutPadding.module.sass';

const CardWithoutPadding = ({
  className,
  title,
  titleRight = "",
  classTitle = 'title-red',
  classCardHead,
  head,
  children,
}) => {
  return (
    <div className={cn(styles.card, className)}>
      {title && (
        <div className={cn(styles.head, classCardHead, "d-flex justify-content-between flex-sm-nowrap flex-wrap")}>
          <div
            className={cn(classTitle, styles.title)}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <div>
            {titleRight}
          </div>
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
};

export default CardWithoutPadding;
