import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

export const BannerDetectorContext = createContext({
  banners: [],
  prependBanner: () => {},
  updateBanner: () => {},
});

export const BannerDetectorProvider = ({ children }) => {
  const [banners, setBanners] = useState([]);

  const prependBanner = useCallback(
    (newBanner) => {
      setBanners((prevState) => [
        { id: prevState?.length + 1, ...newBanner },
        ...prevState,
      ]);
    },
    [setBanners],
  );

  const updateBanner = useCallback(
    (id, newBannerData) => {
      console.log('updateBanner', id, newBannerData);
      const index = banners.findIndex((b) => b.id === id);

      if (index === -1) {
        throw new Error('Banner not found!');
      }

      setBanners((prevState) => {
        const newData = [...prevState];

        newData[index] = {
          ...newData[index],
          predictions: newBannerData?.predictions?.map((newPrediction, i) => ({
            ...(newData[index].predictions[i] ?? {}),
            ...newPrediction,
          })),
        };

        return newData;
      });
    },
    [banners, setBanners],
  );

  const contextValue = useMemo(
    () => ({
      banners,
      prependBanner,
      updateBanner,
    }),
    [banners, prependBanner, updateBanner],
  );

  return (
    <BannerDetectorContext.Provider value={contextValue}>
      {children}
    </BannerDetectorContext.Provider>
  );
};

BannerDetectorContext.Provider.propTypes = {
  value: PropTypes.shape({
    banners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        imageUrl: PropTypes.string,
        predictions: PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            width: PropTypes.number,
            height: PropTypes.number,
            name: PropTypes.string,
          }),
        ),
      }),
    ),
    prependBanner: PropTypes.func,
    updateBanner: PropTypes.func,
  }),
};

export const useBanners = () => useContext(BannerDetectorContext);
